body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Cabin";
  src: url("fonts/CabinRegular-G0GO.ttf");
}
@font-face {
  font-family: "Cabin Bold";
  src: url("fonts/CabinBold-DEDD.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Cabin Italic";
  src: url("fonts/CabinItalic-qmG1.ttf");
  font-style: italic, oblique;
}
@font-face {
  font-family: "Cabin Bold Italic";
  src: url("fonts/CabinBoldItalic-AE3p.ttf");
  font-weight: bold;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Caretodance';
  src: local('Caretodance'), url(./fonts/Caretodance-JrDn.ttf) format('truetype');
}
@font-face {
  font-family: 'CynRegularBold';
  src: local('CynRegularBold'), url(./fonts/CynRegularBold-KdRo.ttf) format('truetype');
}
@font-face {
  font-family: "Eb Garamond";
  src: url("fonts/EbGaramond12Regular-weJw.ttf");
}
@font-face {
  font-family: "Eb Garamond Bold";
  src: url("fonts/EbGaramond08Regular-2mWe.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Eb Garamond Italic";
  src: url("fonts/EbGaramond12Italic-dZKx.ttf");
  font-style: italic, oblique;
}
@font-face {
  font-family: "Eb Garamond Bold Italic";
  src: url("fonts/EbGaramond08Italic-7W1B.ttf");
  font-weight: bold;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Indifference';
  src: local('Indifference'), url(./fonts/Indifference-jYe7.ttf) format('truetype');
}

@font-face {
  font-family: 'OldDogNewTricksCaps';
  src: local('OldDogNewTricksCaps'), url(./fonts/OldDogNewTricksCaps-B0PV.ttf) format('truetype');
}
